import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

export interface ImageProps {
  url: string;
  classes?: {
    wrapper?: string;
    image?: string;
  };
  style?: React.CSSProperties;
}

export const ImageStatic: FC<PropsWithChildren<ImageProps>> = ({
  url,
  classes,
  children,
  style,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleLoad = () => {
    // console.log({ event });
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.getAttribute("listener") !== "true"
    ) {
      imgRef.current.addEventListener("load", handleLoad, { once: true });
    }
    return () => {
      if (imgRef && imgRef.current) {
        handleLoad();
        imgRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, [imgRef.current]);

  return (
    <div className={twMerge("h-4 w-4 overflow-hidden", classes?.wrapper)}>
      <img
        src={url}
        className={twMerge("h-full w-full", classes?.image)}
        style={style}
      />
      {children}
    </div>
  );
};
